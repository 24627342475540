


















































































































































import Vue from 'vue';
import { getUserList, usersNumber } from '@/api/user';
import { hardwarekefu, hardwareHandler } from '@/api/lock';
import { Modal } from 'ant-design-vue';
import moment from 'moment';

export default Vue.extend({
  name: 'userManageList',
  data() {
    return {
      extend3: '',
      cotent: { sequenceNbr: 0 },
      tableLoading: false,
      searchForm: {
        serial: '',
        callUserName: '',
        tel: '',
        status: '0',
      },
      decModal: false,
      decContent: '',
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      userManageColumns: [
        {
          title: '序号',
          dataIndex: 'sortNo',
          width: '220px',
        },
        {
          title: '加密锁业务号',
          dataIndex: 'pooldto.busId',
          width: '220px',
        },
        // {
        //   title: '加密锁设备编码',
        //   dataIndex: 'serial',
        //   width: '220px',
        // },
        {
          title: '联系人',
          dataIndex: 'callUserName',
          width: '120px',
        },
        {
          title: '联系电话',
          dataIndex: 'tel',
          width: '200px',
        },
        {
          title: '问题描述',
          dataIndex: 'description',
          width: '200px',
          scopedSlots: { customRender: 'description' },
        },
        /*{
          title: '附件',
          dataIndex: '',
          width: '220px',
        },*/
        {
          title: '提交时间',
          dataIndex: 'recDate',
          width: '200px',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '问题状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '处理意见',
          dataIndex: 'extend3',
          width: 200,
        },
        {
          title: '处理人',
          width: 100,
          dataIndex: 'executeUserName',
        },
        {
          title: '处理时间',
          width: 120,
          dataIndex: 'executeTime',
          scopedSlots: { customRender: 'executeTime' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      userManageData: [] as any,
      ZBT_DEFAULT: 0,
      ZBT: 0,
      ZBT_WEB: 0,
      visible: false,
      questionContent: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    moment,
    dateFormat(date) {
      if (!date) {
        return null;
      }
      return moment(+date).format('YYYY-MM-DD');
    },
    toUnix(date) {
      if (!date) {
        return '';
      }
      return new Date(date).getTime();
    },
    getStartTime(time) {
      if (time) {
        return moment(time).startOf('day').valueOf();
      } else {
        return '';
      }
    },
    getEndTime(time) {
      if (time) {
        return moment(time).endOf('day').valueOf();
      } else {
        return '';
      }
    },
    fetchData() {
      this.tableLoading = true;
      hardwarekefu({
        ...this.searchForm,
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      }).then((res) => {
        const {
          result: { list, total },
        } = res;
        this.userManageData = list;
        this.userManageData.forEach((item, index) => (item.sortNo = index + 1));
        this.pagination.total = total;
        this.tableLoading = false;
      });
    },
    showModal(text) {
      this.decModal = true;
      text !== '' ? (this.decContent = text) : (this.decContent = '暂无内容');
    },
    reset() {
      //重置
      this.searchForm.serial = '';
      this.searchForm.callUserName = '';
      this.searchForm.tel = '';
      this.searchForm.status = '0';
      this.handleSubmit();
    },
    handleSubmit() {
      this.pagination.current = 1;
      this.fetchData();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.fetchData();
    },
    usersNumber() {
      usersNumber().then((res) => {
        if (res) {
          const data = res.result;
          (this.ZBT_DEFAULT = data.ZBT_DEFAULT),
            (this.ZBT = data.ZBT),
            (this.ZBT_WEB = data.ZBT_WEB);
        }
      });
    },
    resetForm() {
      this.questionContent = '';
      this.visible = false;
      console.log('1111111');
    },
    handleOk(status) {
      hardwareHandler(this.cotent.sequenceNbr, status, this.extend3).then(
        (res) => {
          this.visible = false;
          this.handleSubmit();
        }
      );
    },
    handleAnswer(record) {
      this.cotent = record;
      this.visible = true;
      // this.fetchData();
    },
  },
});
